.experience-section {
  border-radius: 10px;
}

.experience-section h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 12px;
  color: #333;
}

.timeline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border: 1px solid #4f4f4f;
  margin: 20px;
  border-radius: 10px;
  width: 250px;
}

.company-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.experience-details h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
  padding-bottom: 8px;
}

.experience-details h3 {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.experience-details p {
  margin: 0;
  font-size: 12px;
  color: #999;
}