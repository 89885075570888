@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

html {
    scroll-behavior: smooth;
}

h1, h3, h5 {
    /*font-family: 'Akira Expanded', sans-serif;*/
    font-family: 'Plus Jakarta Sans', sans-serif;
    /*font-family: 'Neutro', sans-serif;*/
    /*font-family: 'Ginto Nord', sans-serif;*/
    text-transform: inherit;
    font-weight: 800;
}

a {
    color: #5765f1;
    font-size: 12px;
}

a:hover {
    color: #3e47a9;
    text-decoration: none;
}

.neutro {
    font-family: 'Neutro', sans-serif;
}

p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
}

.footer-about-body p {
    text-align: justify;
}

body {
    background-color: #EFEFEF;
    color: #000000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
}

.hero-title {
    /*font-family: 'Plus Jakarta Sans', sans-serif;*/
    font-family: 'Neutro', sans-serif;
    text-transform: inherit;
    font-weight: 800;
    font-size: 2.5rem;
}

.bottom-bg-style {
    position: relative;
    background-color: #5765f1;
    height: 40vh;
    width: 100%;
    bottom: 0;
    left: 0;
    margin: 0;
    z-index: -20;
    transform-origin: top left;
    transform: skew(0, 10deg);
}

.con-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.con-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.con-left-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
}

.con-right-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.jumbotron {
    /*background-color: #000A2A;*/
    background-color: transparent;
}

.navbar-brand {
    outline: none;
}

.navbar-bg {
    background-color: #efefef;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #5765f1;
}

.nav-name, .footer-name {
    font-family: 'Panipuri', sans-serif;
    margin-bottom: 0;
    font-size: 20px;
}

.nav-right .nav-item {
    margin: 0 8px;
}

.nav-name {
    color: #000;
    font-size: 1.1rem;
}

.nav-name:hover {
    color: #5765f1;
}

.footer-name {
    color: #000;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar {
    color: #000000;
    font-size: 0.875rem;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #EFEFEF;
    backdrop-filter: blur(20px);
    box-shadow: 0 1px 20px #00000014;
    line-height: 1.5;
    z-index: 100;
}

nav li a {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    margin: 0 4px;
}

.navbar-light .navbar-nav .nav-link {
    color: #818181;
    font-size: 0.85rem;
}

.nav-logo {
    width: 118px;
}

.nav-logo-filter {
    /*-webkit-filter: drop-shadow(0 0 30px #343a98);*/
    /*filter: drop-shadow(0 0 30px #343a98);*/
}

.navbar-light .navbar-toggler {
    color: #fff;
    border-color: transparent;
    background-color: transparent;
}

.navbar-light .navbar-toggler-icon {
    border: 2px solid #fff;
}

.sites-nav-link {
    padding: 6px 26px;
    border-radius: 10px;
    color: #5765f1;
    background-color: transparent;
    border: 2px solid #5765f1;
    font-size: 0.775rem;
}

.sites-nav-link:hover {
    color: #ffffff;
    background-color: #5765f1;
    border: 2px solid #5765f1;
}

.footer {
    width: 100%;
    left: 0;
    /*position: absolute;*/
    border-top: 1px solid #ffffff24;
}

.footer-about-body {
    padding: 20px 40px 100px 40px;
}

@media screen and (max-width: 360px) {
    .footer-about-body {
        padding: 60px 0;
    }
}

.footer, .footer-about-body {
    overflow: hidden;
}

.footer p {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.footer h5 {
    margin-top: 4rem;
}

.footer-logo-1 {
    width: 150px;
    padding: 0 10px;
}

.footer-section {
    min-height: 80px;
}

.footer-section .row .col:nth-child(2) {
    text-align: center;
}

.footer-section .row .col:nth-child(3) {
    text-align: right;
}

.footer a {
    /*background-color: rgba(255, 255, 255, 0.34);*/
    padding: 3px 10px;
    border-radius: 5px;
    /*box-shadow: 0 0 20px rgb(0 0 0 / 4%);*/
    color: #ffffff;
}

.footer a:hover {
    color: #e4e6ff;
    text-decoration: none;
}

.footer p {
    margin-bottom: 0;
}

.copyright-text {
    color: #000;
    font-size: 0.5rem !important;
    letter-spacing: 0.1rem;
}

.by-satha-vr {
    color: #fff;
    margin: 0 20px;
}

.by-satha, .by-satha a {
    color: #fff;
    font-size: 0.6rem;
    letter-spacing: 0.2rem;
}

.footer-page-link {
    font-size: 0.8rem;
}

.privacy-divider {
    padding: 0 20px;
    color: #fff;
}

@media screen and (max-width: 992px) {
    .privacy-divider {
        padding: 0 5px;
    }

    .by-satha-vr {
        margin: 0 13px 0 5px;
    }

    .footer-logo-1 {
        width: 100px;
    }
}

@media screen and (max-width: 768px) {

    .by-satha-vr {
        margin: 0 20px;
    }

    .footer-logo-1 {
        width: 150px;
    }

    .footer-section .row .col:nth-child(1) {
        min-width: 100%;
        text-align: center;
    }

    .footer-section .row .col:nth-child(2), .footer-section .row .col:nth-child(3) {
        min-width: 100%;
        text-align: center;
        margin-top: 30px;
    }

    .footer-section .row .col:nth-child(3) {
        min-width: 100%;
        text-align: center;
        margin-top: 0;
    }

    .footer-section {
        padding: 40px 20px;
    }
}

@media screen and (max-width: 332px) {
    .by-satha-vr {
        display: none;
    }

    .by-satha {
        display: block;
        margin-top: 10px;
    }
}

.hero {
    padding: 40px 10px;
    /*min-height: calc(100vh - 57px);*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero .col-4, .hero .col-8 {
    /*border: 1px solid #000;*/
}

.hero-logo {
    width: 250px;
    margin-top: 10px;
}

.hero .row .col-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    border-right: 5px solid #5765f1;
    padding-right: 50px;
}

.hero .row .col-8 {
    text-align: left;
    padding-left: 55px;
}

.hero .edition-name {
    font-size: 0.875rem;
    letter-spacing: 0.1rem;
    font-weight: 800;
}

.edition-list {
    width: 100%;
}

.edition-list .col p {
    margin: 0;
}

.edition-list .col {
    padding: 30px 20px;
}

.edition-list-2 .col {
    border-radius: 10px !important;
}

.edition-list .col:hover {
    background-color: #ffffff;
}

.edition-availability-badge {
    text-transform: uppercase;
    font-size: 0.5rem;
    padding: 7px 15px;
    border-radius: 10px;
    border: 1px solid;
    margin-top: 15px !important;
}

@media screen and (max-width: 992px) {
    .edition-availability-badge {
        font-size: 0.4rem;
    }
}

.edition-availability-badge-red {
    color: #4f4f4f;
    border-color: #4f4f4f;
}

.edition-availability-badge-green {
    color: #4f4f4f;
    border-color: #4f4f4f;
}

.edition-desc {
    font-size: 0.6rem;
    color: #8d8d8d;
}

@media screen and (min-width: 992px) {
    .mob-hero-header {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .pc-hero-header {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .hero {
        padding-top: 40px;
    }

    .mob-hero-header .hero-title {
        margin-top: 40px;
    }
}

/* 545, 575 */

.main-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


.title-list-div {
    width: 100%;
    padding: 40px 5px;
    margin-top: 40px;
}

.title-list {
    font-size: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
}

.main-name {
    font-size: 4rem;
    font-family: 'Neutro', sans-serif;
    margin-bottom: 0;
    padding-bottom: 0;
}

.full-name {
    font-size: 0.675rem;
    letter-spacing: 0.3rem;
    color: #838383;
    padding-top: 0;
    margin: 0 0 30px 0;
    text-align: center;
}

iframe .watermark {
    display: none !important;
}

.panipuri {
    font-family: 'Panipuri', sans-serif;
    color: #5765f1;
}

.main-heading {
    font-size: 3.5rem;
}

.social-icon-row {
    width: 50%;
    text-align: center;
    margin: 0 15px;
}

.social-icon {
    padding: 0;
    margin: 0;
    width: 24px;
}

.contact-details {
    font-size: 0.5rem;
    letter-spacing: 0.1rem;
}

/* top gradient bg - start */
.home-hero-bg-div {
    overflow: hidden;
    width: 100vw;
}

.home-hero-bg {
    position: absolute;
    width: 50vw;
    height: 60vh;
    background: #000a2a;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.11) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
}

.home-hero-bg-1 {
    left: -20vw;
    top: -12vh;
}

.home-hero-bg-2 {
    right: 0;
    bottom: -40vh;
}

.home-hero-bg-3 {
    left: -5vw;
    top: 50vh;
}

/* top gradient bg - end */

.main-section {
    min-height: calc(100vh - 57px);
}

.section-me {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    padding: 80px 20px 0 20px;
}

@media screen and  (max-width: 992px) {
    .section-me {
        padding-top: 160px;
    }
}

.satha-planet {
    width: 300px;
    transform: rotate(-7deg);
    border-radius: 20px 120px 20px 20px;
}

.research {
    padding: 60px 20px 40px 20px;
}

.big-board-div {
    margin-top: 100px;
}

.big-board {
    font-family: 'Panipuri', sans-serif;
    font-size: 4rem;
}

/*.footer-img {*/
/*    width: 100%;*/
/*    position: absolute;*/
/*}*/

.footer-section {
    /*background: linear-gradient(145deg, #5765f1, #5765f1);*/
    padding: 30px;
    margin: 0;
    position: absolute;
    width: 100%;
    left: 0;

    /* 2 */
    /*height: 200px;*/
}

.footer-img {
    position: absolute;
    margin-top: -313px;
    z-index: -1;
    height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position-y: 0px;
    background-position-x: center;
}

.footer-whatever {
    font-size: 1.2rem;
}

.explore h1 {
    font-family: 'Panipuri', sans-serif;
    font-size: 1.2rem;
    color: #4d4d4d;
}

.explore-bg {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
}

.github-timeline {
    width: 80%;
}

@media screen and (max-width: 768px) {
    .github-timeline {
        width: 100%;
    }
}

.list-section {
    margin: 120px 0;
}

.project-list-section {
    margin: 30px 0;
}

.research-block {
    margin: 20px 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 237.5px;
}

.research-up {
    border: 1px solid #ffd000;
}

.research-active {
    border: 1px solid #50e777;
}

.research-open {
    border: 1px solid #5765f1;
}

.research-org {
    border: 1px solid #4f4f4f;
}

.research-badge {
    font-size: 0.675rem;
    font-weight: bold;
    padding: 3px 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    top: -22px;
    left: -1px;
    position: absolute;
}

.research-badge-up {
    background-color: #ffd000;
}

.research-badge-active {
    background-color: #50e777;
}

.research-badge-open {
    background-color: #5765f1;
    color: #fff;
}

.research-badge-org {
    background-color: #4f4f4f;
    color: #fff;
}

.block-href, .block-href:hover, .block-href:active, .block-href:focus {
    color: inherit;
    outline: none;
}

.org-logos-coduza {
    width: 80px;
}

.org-logos {
    width: 200px;
}

.logo-box {
    margin-top: 30px;
}

.logo-box-coduza {
    margin-top: 20px;
}

.social-section .block-href {
    /*margin: 10px;*/
}

.coming-soon-block:hover {
    background-color: transparent !important;
}

.big-board-tel {
    font-size: 2.5rem;
    text-align: right;
    color: #5765f1;
}

.big-board-email {
    font-size: 2.5rem;
    text-align: right;
    color: #000000;
}

.mob-satha-text {
    /*margin-top: 23px;*/
    margin-top: 300px;
}

nav svg:hover {
    fill: #5765f1;
}

/* MOBILE */

@media screen and (min-width: 992px) {
    .mobile-main-planet {
        display: none;
    }
}

@media screen and (max-width: 1015px) {
    .footer a {
        font-size: 10px;
    }
}

@media screen and (max-width: 992px) {
    .main-section {
        height: 100vh;
    }

    .big-board {
        font-size: 3rem;
    }

    .mobile-main-planet {
        margin-top: -150px;
        z-index: -1;
    }

    .mob-planet-bg {
        height: calc(100vh - 57px);
        /*width: 100%;*/
        width: calc(100% - 45px);
        top: 57px;
        /*left: -100px;*/
        left: 45px;
        position: absolute;
    }

    .explore {
        margin-top: -20px;
    }

    .title-neutro {
        font-size: 2rem;
    }

    .research-block {
        max-width: inherit;
        min-width: 200px;
    }

    .title-neutro-2 {
        font-size: 1.2rem;
    }

    .big-board-tel, .big-board-email {
        font-size: 1.5rem;
    }

    .about-planet-div {
        max-width: 90%;
        right: 0;
        margin-top: -50px;
        overflow: hidden;
        position: absolute;
    }

    .about-planet-text {
        margin-top: 400px;
    }

    .row {
        margin: 0;
    }

    .col {
        padding: 0;
    }

    .list-section {
        margin: 80px 0;
    }

    .big-board-div {
        margin-top: 40px;
    }

    .footer-img {
        /*margin-top: 55px;*/
        margin-top: -65px;
        height: 200px;
        background-size: 100%;
    }

    .privacy-divider {
        color: #ffffff;
    }

    .footer a {
        color: #ffffff;
        font-size: 9px;
    }

    .copyright-text {
        color: #fff;
    }

    .contact-details {
        margin-top: 60px;
        margin-bottom: 70px;
    }

    .footer-section .row .col:nth-child(2) {
        /*margin-top: 120px;*/
    }

    .footer-whatever {
        font-size: 0.9rem;
    }

    .edition-list-1 .col {
        min-width: 100%;
    }

    .footer-section .row .col:nth-child(2), .footer-section .row .col:nth-child(3) {
        margin-top: 0;
    }
}

@media screen and (max-width: 468px) {
    .mobile-main-planet {
        margin-top: -350px;
    }
}

@media screen and (max-width: 991px) {
    .pc-social-nav {
        display: none;
    }

    .mob-social-nav {
        padding: 8px;
    }
}

@media screen and (min-width: 992px) {
    .mob-social-nav {
        display: none;
    }
}

@media screen and (max-width: 356px) {
    .mobile-main-planet {
        margin-left: -40px;
    }

    .section-me {
        margin-top: 50px;
    }

    .mob-satha-text {
        margin-top: 280px;
    }

    .mob-hero-header .hero-title {
        margin-top: 10px;
    }
}

@media screen and (max-width: 327px) {
    .footer-img {
        /*margin-top: 85px;*/
        margin-top: -63px;
        background-position-y: 25px;
    }

    .footer-section .row .col:nth-child(2) {
        /*padding-top: 30px;*/
    }
}

@media screen and (min-width: 405px) {
    .footer a, .privacy-divider, .copyright-text {
        color: #000;
    }
}

@media screen and (min-width: 993px) {
    .footer a, .privacy-divider {
        color: #fff;
    }
}

@media screen and (max-width: 468px) {
    .tablet-planet {
        display: none;
    }
}

@media screen and (min-width: 468px) {
    .mobile-planet {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .main-heading {
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 564px) {
    .main-heading {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 480px) {
    .main-heading {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 380px) {
    .main-heading {
        font-size: 2rem;
        /*margin-top: 30px;*/
    }
}

@media screen and (max-width: 330px) {
    .main-heading {
        margin-top: 0;
    }
}

@media screen and (min-width: 768px) {
    .tablet-planet {
        margin-left: 125px;
    }
}

@media screen and (max-width: 992px) {
    .about-planet-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

@media screen and (max-width: 440px) {
    .about-planet-div {
        display: block;
        flex-direction: inherit;
        justify-content: inherit;
        align-items: inherit;
    }
}

@media screen and (max-width: 1093px) {
    .footer-img {
        background-position-y: 40px;
    }
}

@media screen and (max-width: 992px) {
    .footer-img {
        background-position-y: 0;
    }
}

@media screen and (max-width: 331px) {
    .footer-img {
        background-position-y: 17px;
    }
}

@media screen and (max-width: 992px) {
    .nav-contact-btn-mob {
        margin-bottom: 20px !important;
    }
}

/* Privacy */
.privacy {
    font-family: Arial, serif !important;
}

.privacy h1, .privacy h2 {
    text-transform: uppercase;
    font-weight: bold;
}

.privacy h1 {
    font-siZe: 26px;
}

.privacy h2 {
    font-siZe: 19px;
    margin: 48px 0 16px;
}

.privacy p {
    font-family: Arial, serif !important;
    font-size: 14px;
    line-height: 115%;
}

/* 404 Not Found Page */

.not-found {
    width: 100%;
    min-height: calc(100vh - 57px);
}

.not-found-img {
    width: 100px;
}

.not-found-head, .not-found-head img, .not-found-head h1 {
    display: inline-block;
}

.other-services {
    margin-top: 40px;
}

.other-services h3 {
    text-align: center;
}

.life-goal {
    width: 800px;
    /*text-align: left;*/
    font-size: 16px;
    color: rgb(68, 68, 68);
}

.life-game-h3 {
    width: 800px;
    /*text-align: left;*/
}

@media screen and (max-width: 992px) {
    .life-goal, .life-game-h3 {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .life-goal, .life-game-h3 {
        width: 100%;
    }
}

.life-game-col-1 {
    width: 650px;
}

.life-game-col-1 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: #5765f1;
    padding: 40px 30px;
}

.life-game-col-1 p, .life-game-col-2 p {
    margin: 0;
}

.life-game-col-2 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px dashed #5765f1;
    padding: 40px 30px;
}

@media screen and (max-width: 992px) {
    .life-game-col-1 {
        min-width: 100%;
        border: none;
    }

    .life-game-col-2 {
        border-radius: 10px;
    }
}

@media screen and (max-width: 549px) {
    .scientifically-neutro {
        font-size: 17px;
    }
}

.mob-name {
    font-size: 18px;
    line-height: 0;
}

@media screen and (max-width: 380px) {
    .mob-name {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .brand-footer-col {
        visibility: hidden;
    }
}